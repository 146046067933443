import { defineStore } from 'pinia'
import logo from '@/assets/images/logo.svg?raw'
import logoIcon from '@/assets/images/logo-icon.svg?raw'

export const useConfigStore = defineStore('config', {
  state: () => {
    return {
      title: 'Hub',
      logo: h('div', { innerHTML: logo }),
      logoIcon: h('div', { innerHTML: logoIcon }),
      theme: 'light',
      i18nLocale: 'en',
      isNavigationCollapsed: false
    }
  },
  getters: {
    isRtl() {
      return this.i18nLocale === 'ar'
    }
  },
  persist: {
    storage: localStorage,
    paths: ['theme', 'i18nLocale', 'isNavigationCollapsed']
  }
})
